
<template>
  <b-container fluid>
    <!-- Search Form -->
    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('VirtualMuseum.configuration.information_type')"
              label-for="info_type_id"
            >
              <b-form-select
                plain
                id="info_type_id"
                :options="infoTypeList"
                v-model="search.information_type"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <!-- /Search Form -->
    <b-row>
      <b-col md="12">
        <iq-card>
          <!-- Add New -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('VirtualMuseum.configuration.information_type') + ' ' + $t('globalTrans.list')}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-3 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <!-- /Add New -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">

                  <!-- Table -->
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>

                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>

                    <template v-slot:cell(action)="data">
                      <a href="javascript:" :title="$t('globalTrans.edit')" class="btn_table_action table_action_edit" v-b-modal.modal-3 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" :title="$t('globalTrans.active')" class="btn_table_action table_action_status" v-if="data.item.status == 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" :title="$t('globalTrans.inactive')" class="btn_table_action table_action_toggle" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>

                    </template>
                  </b-table>

                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-3" size="lg" :title="formTitle"  :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { informationTypeListApi, informationTypeStatusApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
     Form
  },
  data () {
    return {
      search: {
        information_type: ''
      },
      editItemId: 0,
      rows: []
    }
  },
  computed: {
    formTitle () {
        if (this.editItemId === 0) {
            return this.$t('VirtualMuseum.configuration.information_type') + ' ' + this.$t('globalTrans.entry')
        } else {
            return this.$t('VirtualMuseum.configuration.information_type') + ' ' + this.$t('globalTrans.modify')
        }
    },
    infoTypeList: function () {
        const objectData = this.$store.state.VirtualMuseum.commonObj.informationTypeList.filter(item => item.status === 1)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    columns () {
      // Table Column Labels
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('VirtualMuseum.configuration.information_type'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center fixed-width' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  methods: {
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(virtualMuseumServiceBaseUrl, informationTypeStatusApi, item, 'information_type', 'informationTypeList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(virtualMuseumServiceBaseUrl, informationTypeListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
<style lang="scss">
  .fixed-width {
    width: 180px !important;
  }
</style>
